<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-model="options" @handleEvent="handleEvent" />
    <Dialog :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";
import {
  getFreshlyRoomList,
  delRoom,
  freshlyRoomReduction,
} from "@/api/freshlyBaked/base/room";

export default {
  name: "room",
  components: { TablePage, Dialog },
  data() {
    return {
      dialogOptions: {
        title: "",
        width: 620,
        show: false,
        type: "Room",
        formData: null,
      },
      options: {
        uuid: "e33df305-cfee-22ba-9c98-66f5fd01bbad",
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        defaultBody: { delFlag: 0 },
        getListApi: getFreshlyRoomList,
        body: {},
        listNo: true, // 序号
        title: "现烤间管理",
        check: [], // 选中数据
        rowKey: "freshlyBakedRoomId",
        search: [
          {
            type: "input",
            tip: "现烤间编号/现烤间名称",
            model: "",
            filter: "query",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            alertText: "确认要删除选中现烤间吗？",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            btnType: "dropdown",
            other: [
              {
                click: "delRecovery",
                label: "回收站",
                multiple: false,
              },
            ],
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            right: true,
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "freshlyBakedRoomNo",
            label: "现烤间编号",
            type: "link",
            click: "update",
            minWidth: 110,
          },
          {
            prop: "freshlyBakedRoomName",
            label: "现烤间名称",
            minWidth: 110,
          },
          {
            prop: "freshlyBakedRoomStoreName",
            label: "所属仓库",
            minWidth: 100,
          },
          {
            prop: "freshlyBakedCaseName",
            label: "现烤商品方案名称",
            minWidth: 250,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 100,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 80,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 150,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 80,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 150,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          this.$refs.tablePage.getList();
          break;
        case "update":
          {
            const options = {
              show: true,
              title: "编辑现烤间",
              click: type,
              formData: row,
              data: {
                type: "freshlyBakedRoom",
              },
            };
            this.dialogOptions = {
              title: "",
              width: 620,
              show: false,
              type: "Room",
              formData: null,
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增现烤间",
              click: type,
              data: {
                type: "freshlyBakedRoom",
              },
              formData: {},
            };
            this.dialogOptions = {
              title: "",
              width: 620,
              show: false,
              type: "Room",
              formData: null,
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del": // 选中数据 Array
          try {
            await delRoom(this.options.check.map((x) => x.freshlyBakedRoomId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        case "delRecovery":
          this.dialogOptions = {
            type: "ShopInfo",
            show: true,
            title: "回收站",
            width: "75vw",
            data: {
              getListApi: getFreshlyRoomList,
              restoreListApi: freshlyRoomReduction,
              type: "decorationRoom",
              id: "freshlyBakedRoomId",
              search: [
                {
                  type: "filters",
                  isRestore: true,
                  model: "",
                  filters: [
                    { filter: "query", label: "全部" },
                    { filter: "freshlyBakedRoomNos", label: "现烤间编号" },
                    { filter: "freshlyBakedRoomNames", label: "现烤间名称" },
                  ],
                },
                {
                  type: "button",
                  tip: "查询",
                  btnType: "primary",
                  click: "search",
                  isRestore: true,
                },
              ],
              columns: [
                {
                  prop: "freshlyBakedRoomNo",
                  label: "现烤间编号",
                  minWidth: 180,
                },
                {
                  prop: "freshlyBakedRoomName",
                  label: "现烤间名称",
                  minWidth: 180,
                },
                {
                  prop: "remark",
                  label: "备注",
                  minWidth: 120,
                },
                {
                  prop: "updateBy",
                  label: "修改人",
                  minWidth: 120,
                },
                {
                  prop: "updateTime",
                  label: "修改时间",
                  minWidth: 155,
                },
                {
                  prop: "createBy",
                  label: "创建人",
                  minWidth: 120,
                },
                {
                  prop: "createTime",
                  label: "创建时间",
                  minWidth: 155,
                },
              ],
            },
          };
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
