<template>
  <div class="wrap">
    <TablePage v-model="options" @handleEvent="handleEvent" />
    <Dialog ref="tablePage" :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from "@/components/tablePage";
import Dialog from "@/components/Dialog";
import { getManList, remove } from "@/api/freshlyBaked/base/roomMan";
export default {
  name: "roomMan",
  components: { TablePage, Dialog },
  data() {
    return {
      dialogOptions: {
        title: "",
        width: 620,
        show: false,
        type: "RoomMan",
        formData: {},
      },
      options: {
        uuid: "f853f124-3164-95cd-6ddc-16c83360e4e0",
        mutiSelect: true, // 多选
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15,
        },
        getListApi: getManList,
        body: {},
        listNo: true, // 序号
        title: "现烤间账号管理",
        check: [], // 选中数据
        rowKey: "appManId",
        search: [
          {
            type: "input",
            tip: "员工编号、姓名、手机号",
            model: "",
            filter: "query",
          },
          { type: "button", tip: "查询", btnType: "primary", click: "search" },
        ],
        buttons: [
          {
            click: "add",
            label: "新增",
            icon: "el-icon-plus",
            type: "primary",
          },
          {
            click: "del",
            label: "删除",
            type: "danger",
            icon: "el-icon-delete",
            disabled: () => !this.options?.check?.length,
          },
          {
            click: "refresh",
            label: "刷新",
            icon: "el-icon-refresh",
            type: "",
          },
        ],
        columns: [
          {
            prop: "appManNo",
            label: "员工编号",
            type: "link",
            click: "update",
            minWidth: 110,
          },
          {
            prop: "appManName",
            label: "员工姓名",
            minWidth: 110,
          },
          {
            prop: "telephone",
            label: "手机号",
            minWidth: 100,
          },
          {
            prop: "freshlyBakedRoomInfos",
            label: "所属现烤间",
            type: "tag",
            tagKey: "freshlyBakedRoomInfos",
            tagLabel: "freshlyBakedRoomName",
            minWidth: 250,
          },
          {
            prop: "remark",
            label: "备注",
            minWidth: 100,
          },
          {
            prop: "deleteFlag",
            label: "删除状态",
            minWidth: 80,
          },
          {
            prop: "createBy",
            label: "创建人",
            minWidth: 80,
          },
          {
            prop: "createTime",
            label: "创建时间",
            minWidth: 150,
          },
          {
            prop: "updateBy",
            label: "修改人",
            minWidth: 80,
          },
          {
            prop: "updateTime",
            label: "修改时间",
            minWidth: 150,
          },
        ],
        list: [],
      },
    };
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case "dialogChange":
          console.log("this.$refs", this.$refs);
          this.$refs.tablePage.getList();
          break;
        case "update":
          {
            const options = {
              show: true,
              title: "编辑现烤间员工",
              click: type,
              data: {
                type: "freshlyBaked",
              },
              formData: row,
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "add":
          {
            const options = {
              show: true,
              title: "新增现烤间员工",
              click: type,
              data: {
                type: "freshlyBaked",
              },
              formData: {},
            };
            this.dialogOptions = { ...this.dialogOptions, ...options };
          }
          break;
        case "del": // 选中数据 Array
          try {
            await remove(this.options.check.map((x) => x.appManId));
            this.$message.success("删除成功！");
            this.$refs.tablePage.getList();
          } catch (error) {}
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  .dialog-footer {
    height: 80px;
  }
}
</style>
